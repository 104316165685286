export const NO_VALUE = 'NO_VALUE';
export const FALSE_VALUE = 'false';
export const TRUE_VALUE = 'true';

export enum FEATURE_FLAG {
  ADOBE_ANALYTICS_DISABLED = 'ADOBE_ANALYTICS_DISABLED',
  IS_DEV_ASSIGNMENT_MODAL_ENABLED = 'IS_DEV_ASSIGNMENT_MODAL_ENABLED',
  IS_PERFORMANCE_NAV_ENABLED = 'IS_PERFORMANCE_NAV_ENABLED',
  IS_STUDENT_PERFORMANCE_NAV_ENABLED = 'IS_STUDENT_PERFORMANCE_NAV_ENABLED',
  IS_INSTRUCTOR_PERFORMANCE_NAV_ENABLED = 'IS_INSTRUCTOR_PERFORMANCE_NAV_ENABLED',
  SYLLABUS_NEST_LEVEL_LIMIT = 'SYLLABUS_NEST_LEVEL_LIMIT',
  BOOK_AD_PM_CODE = 'BOOK_AD_PM_CODE',
  ENABLE_BATCH_CONTENT = 'ENABLE_BATCH_CONTENT',
  ENABLE_BATCH_CONTENT_UAT_CLICKS = 'ENABLE_BATCH_CONTENT_UAT_CLICKS',
  ENABLE_FEATURE_FLAG_BASED_BATCH_CONTENT = 'ENABLE_FEATURE_FLAG_BASED_BATCH_CONTENT',
  ENABLE_SKILL_OPEN_IN_SAME_WINDOW = 'ENABLE_SKILL_OPEN_IN_SAME_WINDOW',
  IS_SITE_DOWN_FOR_MAINTENANCE = 'IS_SITE_DOWN_FOR_MAINTENANCE',
  CATALOG_ITEM_IS_PUBLISHED = 'CATALOG_ITEM_IS_PUBLISHED',
  CATALOG_ITEM_PUBLISH_DATE = 'CATALOG_ITEM_PUBLISH_DATE',
  IS_UNSUPPORTED_EVOLVE_RESOURCE_TYPE = 'IS_UNSUPPORTED_EVOLVE_RESOURCE_TYPE',
  IS_COURSE_BUILDER_EVOLVE_RESOURCE_TYPE = 'IS_COURSE_BUILDER_EVOLVE_RESOURCE_TYPE',
  IS_EAQ_AUTHESS_APP_LINK_ENABLED = 'IS_EAQ_AUTHESS_APP_LINK_ENABLED',
  IS_EAQ_AUTHESS_APP_LINK_STUDENT_OPEN_NEW_TAB_ENABLED = 'IS_EAQ_AUTHESS_APP_LINK_STUDENT_OPEN_NEW_TAB_ENABLED',
  IS_CUSTOM_GRADE_POINTS_ENABLED = 'IS_CUSTOM_GRADE_POINTS_ENABLED',
  IS_ASSIGNMENT_EDITOR_SELECT_STUDENTS_ENABLED = 'IS_ASSIGNMENT_EDITOR_SELECT_STUDENTS_ENABLED',
  IS_EAB_COURSE_COPY_ENABLED = 'IS_EAB_COURSE_COPY_ENABLED',
  IS_EAB_UPDATES_AND_TRAINING_HELP_LINK_ENABLED = 'IS_EAB_UPDATES_AND_TRAINING_HELP_LINK_ENABLED',
  ENABLE_DEEP_LINKING = 'ENABLE_DEEP_LINKING',
  ENABLE_BASE_TEMPLATE_FEEDBACK_LINK = 'ENABLE_BASE_TEMPLATE_FEEDBACK_LINK',
  ENABLE_HESI_NG_COURSES_IN_COURSE_SWITCHER = 'ENABLE_HESI_NG_COURSES_IN_COURSE_SWITCHER',
  IS_EAQ_AUTHESS_INS_APP_LINK_ENABLED = 'IS_EAQ_AUTHESS_INS_APP_LINK_ENABLED',
  IS_ADD_AUTHESS_FLAG_TO_OUTPOST_BODY = 'IS_ADD_AUTHESS_FLAG_TO_OUTPOST_BODY',
  ENABLE_AUTHESS_HEALTH_CHECK_FOR_STUDENTS = 'ENABLE_AUTHESS_HEALTH_CHECK_FOR_STUDENTS',
  IS_CHECK_COURSE_ENTITLEMENTS_ON_COURSE_SWITCHER = 'IS_CHECK_COURSE_ENTITLEMENTS_ON_COURSE_SWITCHER',
  IS_ADD_EXTERNAL_LINK_ACTION_ENABLED = 'IS_ADD_EXTERNAL_LINK_ACTION_ENABLED',
  ENABLE_OSMOSIS_VIDEOS = 'ENABLE_OSMOSIS_VIDEOS',
  IS_RIGHTNOW_INTEGRATION_ENABLED = 'IS_RIGHTNOW_INTEGRATION_ENABLED',
  IS_CATALOG_ADD_BTN_ENABLED = 'IS_CATALOG_ADD_BTN_ENABLED',
  STUDENT_RESOURCES_NAV_ITEM_FLAVOR = 'STUDENT_RESOURCES_NAV_ITEM_FLAVOR',
  IS_MY_EVOLVE_LINK_ENABLED = 'IS_MY_EVOLVE_LINK_ENABLED',
  IS_DISABLE_SIMCHART_BULK_ASSIGNMENT_CREATE = 'IS_DISABLE_SIMCHART_BULK_ASSIGNMENT_CREATE',
  IS_OPEN_SKILL_NEW_TAB_MSG_ENABLED = 'IS_OPEN_SKILL_NEW_TAB_MSG_ENABLED',
  IS_SHERPATH_SIMULATIONS_ADD_BTN_HIDDEN = 'IS_SHERPATH_SIMULATIONS_ADD_BTN_HIDDEN',
  IS_STUDENT_LAUNCH_BUTTON_ENABLED = 'IS_STUDENT_LAUNCH_BUTTON_ENABLED',
  IS_ENABLE_SHERPATH_TESTBANK = 'IS_ENABLE_SHERPATH_TESTBANK',
  RE_ATTEMPT_ASSESSMENT_STATUS_ENABLED = 'RE_ATTEMPT_ASSESSMENT_STATUS_ENABLED',
  IS_UPDATE_NAME_LINK_ENABLED = 'IS_UPDATE_NAME_LINK_ENABLED',
  ENABLE_REMOVE_STUDENT_FROM_COURSE = 'ENABLE_REMOVE_STUDENT_FROM_COURSE',
  ENABLE_ADD_STUDENT_TO_COURSE = 'ENABLE_ADD_STUDENT_TO_COURSE',
  IS_ENABLE_LIST_SCROLLER = 'IS_ENABLE_LIST_SCROLLER',
  IS_ENABLE_STU_VANTAGE_TRAINING_LINK = 'IS_ENABLE_STU_VANTAGE_TRAINING_LINK',
  ENABLE_EAQ_ASSIGNMENT_MANAGEMENT_APP_CREATE_EDIT = 'ENABLE_EAQ_ASSIGNMENT_MANAGEMENT_APP_CREATE_EDIT',
  ENABLE_EAQ_STUDENT_STUDY_APP_SELF_STUDY = 'ENABLE_EAQ_STUDENT_STUDY_APP_SELF_STUDY',
  ENABLE_CHATBOT = 'ENABLE_CHATBOT',
  ENABLE_CHATBOT_BY_ISBN = 'ENABLE_CHATBOT_BY_ISBN',
  KILL_CHATBOT = 'KILL_CHATBOT',
  EAQ_EDITOR_CANARY_CONFIG = 'EAQ_EDITOR_CANARY_CONFIG',
  CHATBOT_CANARY_CONFIG = 'CHATBOT_CANARY_CONFIG',
  ENABLE_ITEM_COLORS = 'ENABLE_ITEM_COLORS',
  IS_CATALOG_FOLDER_VIEW_ENABLED = 'IS_CATALOG_FOLDER_VIEW_ENABLED',
  IS_DIRECT_CHATBOT_ISBN = 'IS_DIRECT_CHATBOT_ISBN',
  IS_EVOLVE_SUB_TOPIC_FILTERS_ENABLED = 'IS_EVOLVE_SUB_TOPIC_FILTERS_ENABLED',
  IS_ENABLE_REVIEW_QUIZ = 'IS_ENABLE_REVIEW_QUIZ',
  ENABLE_SKILLS_IN_STUDENT_RESOURCES = 'ENABLE_SKILLS_IN_STUDENT_RESOURCES',
  SHERPATH_AI_QUICK_LINKS_PILL = 'SHERPATH_AI_QUICK_LINKS_PILL',
  HIDE_MAKE_RESOURCES_VISIBLE_POPUP = 'HIDE_MAKE_RESOURCES_VISIBLE_POPUP',
  HIDE_SIMULATION_ACTIONS = 'HIDE_SIMULATION_ACTIONS',
}

export const SHER_EVOL_COURSE_SETTINGS = 'SHER_EVOL_COURSE_SETTINGS';

export enum COURSE_SETTINGS_FEATURE_FLAG {
  DISABLE_CHATBOT_LINK_VISIBILITY_UNTIL = 'DISABLE_CHATBOT_LINK_VISIBILITY_UNTIL',
}

export const AB_TEST = 'AB_TEST';

export const AB_TEST_CONFIG_MAP: Partial<Record<FEATURE_FLAG, string[]>> = {};
