import {
  cloneDeep,
  partition
} from 'lodash';
import { RecTaxonomyNodeDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { ELSDropDownOption } from '../els.components';
import { SELECT_OPTION } from '../../utilities/app.utilities';
import {
  EbookFilterChangeProps,
  EbookFilterField,
  EbookFilterState
} from './ebook-filter.models';
import { mapToIds } from '../../utilities/common.utilities';
import { getHesiTaxonIds } from '../../pages/course-plan/syllabus.utilities';
import { getTaxonomyBookNodes } from '../../pages/catalog/catalog.utilities';
import {
  PrimaryTaxonomy,
} from '../../apis/rec-gateway/rec-gateway.models';

export const handleChapterCheckboxClick = (props: EbookFilterChangeProps, state: EbookFilterState): EbookFilterState => {

  const {
    bookId,
    checkboxValue,
    chapterId
  } = props;

  const ebookFilterState = cloneDeep(state) || {};

  if (!ebookFilterState[bookId]) {
    ebookFilterState[bookId] = {
      selected: false,
      stageChapterSelected: checkboxValue,
      stageChapterId: '',
      chapters: []
    };
  }

  if (checkboxValue) {
    ebookFilterState[bookId].selected = false;
  }

  if (!chapterId) {
    ebookFilterState[bookId].stageChapterSelected = checkboxValue;
  } else {
    ebookFilterState[bookId].chapters = ebookFilterState[bookId].chapters.map((chapter) => {
      return {
        ...chapter,
        selected: chapter.id === chapterId ? checkboxValue : chapter.selected
      };
    }).filter((chapter) => {
      if (checkboxValue) {
        return true;
      }
      return chapter.id !== chapterId;
    });
  }

  return ebookFilterState;
};

export const handleChapterSelectChange = (props: EbookFilterChangeProps, state: EbookFilterState): EbookFilterState => {

  const {
    bookId,
    dropdownValue,
    chapterId
  } = props;

  const ebookFilterState = cloneDeep(state) || {};

  if (!ebookFilterState[bookId]) {
    ebookFilterState[bookId] = {
      selected: false,
      stageChapterSelected: false,
      stageChapterId: '',
      chapters: []
    };
  }

  if (!chapterId) {

    if (!ebookFilterState[bookId].chapters.length) {
      ebookFilterState[bookId].chapters = [];
    }

    ebookFilterState[bookId].chapters.push({
      id: dropdownValue,
      selected: ebookFilterState[bookId].stageChapterSelected
    });

  } else {
    ebookFilterState[bookId].chapters = ebookFilterState[bookId].chapters.map((chapter) => {
      return {
        ...chapter,
        id: chapter.id === chapterId ? dropdownValue : chapter.id
      };
    });
  }

  return ebookFilterState;
};

export const handleEbookCheckboxClick = (props: EbookFilterChangeProps, state: EbookFilterState): EbookFilterState => {

  const {
    bookId,
    checkboxValue
  } = props;

  const ebookFilterState = cloneDeep(state) || {};

  if (!ebookFilterState[bookId]) {
    ebookFilterState[bookId] = {
      selected: checkboxValue,
      stageChapterSelected: false,
      stageChapterId: '',
      chapters: []
    };
  }

  ebookFilterState[bookId].selected = checkboxValue;

  if (checkboxValue) {
    ebookFilterState[bookId].stageChapterSelected = false;
    ebookFilterState[bookId].chapters = ebookFilterState[bookId].chapters.map((chapter) => {
      return {
        ...chapter,
        selected: false
      };
    });
  }

  return ebookFilterState;
};

export const getEbookStateStateOnChange = (props: EbookFilterChangeProps, state: EbookFilterState): EbookFilterState => {
  if (props.field === EbookFilterField.EBOOK_CHECKBOX) {
    return handleEbookCheckboxClick(props, state);
  }
  if (props.field === EbookFilterField.CHAPTER_DROPDOWN) {
    return handleChapterSelectChange(props, state);
  }
  if (props.field === EbookFilterField.CHAPTER_CHECKBOX) {
    return handleChapterCheckboxClick(props, state);
  }
  return {};
};

export const getSelectedTaxonomiesFromEbookFilterState = (state: EbookFilterState): string[] => {
  if (!state) {
    return [];
  }
  const keys = Object.keys(state);
  if (!keys || !keys.length) {
    return [];
  }
  const selectedPartition = partition(keys, (key) => {
    return state[key].selected;
  });

  const selectedChapters = selectedPartition[1].reduce((acc, cur) => {
    const _selectedChapters = state[cur].chapters.filter((chapter) => {
      return chapter.selected;
    }).map((chapter) => {
      return chapter.id;
    });
    return [...acc, ..._selectedChapters];
  }, []);

  return [...selectedPartition[0], ...selectedChapters];
};

export const isEbookSelected = (bookTaxonomy: RecTaxonomyNodeDto, state: EbookFilterState): boolean => {
  return state && state[bookTaxonomy.id] && state[bookTaxonomy.id].selected;
};

export const isStageChapterSelected = (bookTaxonomy: RecTaxonomyNodeDto, state: EbookFilterState): boolean => {
  return state && state[bookTaxonomy.id] && state[bookTaxonomy.id].stageChapterSelected;
};

export const getChapterOptions = (bookTaxonomy: RecTaxonomyNodeDto, catalog: CatalogWithExternalEntitiesDto, state: EbookFilterState, chapterId: string): ELSDropDownOption[] => {

  const hesiTaxonIds = getHesiTaxonIds(catalog);

  const allTaxonIdArraysFromContent = catalog.catalog.data.map(catalogData => {
    return catalogData.relationships.taxonomies.data.map(taxonData => {
      return taxonData.id;
    });
  });

  // if a content taxon array has at least one HESI taxon, then get all taxons from that array
  // this ensures that all chapter options with that content it will be labeled HESI
  const allTaxonIdsOfContentWithSomeHesiTaxonIds = allTaxonIdArraysFromContent.filter(taxonAry => {
    return taxonAry.find(taxon => hesiTaxonIds.includes(taxon));
  }).flatMap(x => x);

  const options = bookTaxonomy.relationships.children.data
    .map((chapter) => {
      const taxonomyNode = catalog.catalog.included.find((node) => {
        return node.id === chapter.id;
      });

      let name = taxonomyNode.attributes.text;

      if (allTaxonIdsOfContentWithSomeHesiTaxonIds.includes(taxonomyNode.id)) {
        name = `${name} (HESI Focus Chapter)`;
      }

      return {
        value: chapter.id,
        name,
        taxonomyNode
      };
    })
    .sort((a, b) => {
      return a.taxonomyNode.attributes.displayOrder - b.taxonomyNode.attributes.displayOrder;
    });

  const filteredOptions: ELSDropDownOption[] = options.filter((option) => {
    if (!state || !state[bookTaxonomy.id]) {
      return true;
    }

    if (chapterId === option.value) {
      return true;
    }

    return !state[bookTaxonomy.id].chapters
      .map(mapToIds).includes(option.value);
  });

  if (!chapterId) {
    filteredOptions.unshift(SELECT_OPTION);
  }

  return filteredOptions;
};

export const getEbookTaxonomies = (props: {
  catalog: CatalogWithExternalEntitiesDto;
  primaryTaxonomies: PrimaryTaxonomy[];
}): RecTaxonomyNodeDto[] => {
  const {
    catalog,
    primaryTaxonomies
  } = props;

  if (!catalog || !primaryTaxonomies || !primaryTaxonomies.length) {
    return [];
  }

  return getTaxonomyBookNodes(catalog).filter((catalogBookTaxon) => {
    return primaryTaxonomies.find((primaryTaxonomy) => {
      return primaryTaxonomy.taxonomy.data[0].id === catalogBookTaxon.id;
    });
  });
};
